var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "content-class": "rounded-lg elevation-2",
      "value": _vm.value,
      "persistent": "",
      "width": "1000"
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        return _vm.$emit('close');
      }
    }
  }, [_c('v-card', {
    attrs: {
      "max-height": "90vh"
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('administration.notes.title')) + " "), _c('v-spacer'), _vm.functions.hasSufficientRights(_vm.constants.Rights.NOTE_CREATE) ? _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "color": "primary",
      "elevation": "0",
      "small": ""
    },
    on: {
      "click": function click($event) {
        _vm.state.isAddEditNoteDialogOpen = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('administration.notes.form.add.title')) + " ")]) : _vm._e()], 1), _c('v-card-text', {
    staticClass: "my-5 content__height"
  }, [_vm.notes.length ? _c('v-simple-table', [_c('thead', [_c('tr', [_c('td', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("administration.notes.table.col.title.id")) + " ")]), _c('td', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("administration.notes.table.col.title.title")) + " ")]), _c('td', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("administration.notes.table.col.title.type")) + " ")]), _c('td', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("administration.notes.table.col.title.content")) + " ")]), _c('td', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("administration.notes.table.col.title.parent")) + " ")]), _c('td', {
    staticClass: "font-weight-bold"
  })])]), _c('tbody', _vm._l(_vm.notes, function (note) {
    return _c('tr', {
      key: note.id,
      staticClass: "table-row-color"
    }, [_c('td', [_vm._v(" " + _vm._s(note.id) + " ")]), _c('td', [_vm._v(" " + _vm._s(note.title) + " ")]), _c('td', [_vm._v(" " + _vm._s(note.type) + " ")]), _c('td', [_vm._v(" " + _vm._s(note.content) + " ")]), _c('td', [_vm._v(" " + _vm._s(note.parentId) + " ")]), _c('td', {
      staticClass: "text-right"
    }, [_vm.functions.hasSufficientRights(_vm.constants.Rights.NOTE_UPDATE) ? _c('v-btn', {
      staticClass: "mx-2",
      attrs: {
        "icon": "",
        "x-small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.listeners.onClickEdit(note);
        }
      }
    }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiPencil) + " ")])], 1) : _vm._e(), _c('v-menu', {
      attrs: {
        "offset-y": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on,
              attrs = _ref.attrs;
          return [_vm.functions.hasSufficientRights(_vm.constants.Rights.NOTE_DELETE) ? _c('v-btn', _vm._g(_vm._b({
            attrs: {
              "icon": "",
              "x-small": ""
            }
          }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiDelete) + " ")])], 1) : _vm._e()];
        }
      }], null, true)
    }, [_c('v-card', {
      attrs: {
        "width": "400px"
      }
    }, [_c('v-card-title', {
      staticClass: "pb-0 text-subtitle-2 break-word"
    }, [_vm._v(" " + _vm._s(_vm.$t('administration.notes.delete.text')) + " ")]), _c('v-card-actions', [_c('v-btn', {
      staticClass: "rounded-lg",
      attrs: {
        "small": "",
        "outlined": "",
        "elevation": "0"
      },
      on: {
        "click": function click($event) {
          return _vm.listeners.onClickDelete(note);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('form.yes')) + " ")]), _c('v-spacer'), _c('v-btn', {
      staticClass: "rounded-lg",
      attrs: {
        "small": "",
        "outlined": "",
        "elevation": "0"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('form.no')) + " ")])], 1)], 1)], 1)], 1)]);
  }), 0)]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('administration.notes.noNotes')) + " ")])], 1), _c('v-card-actions', [_c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0",
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('form.close')) + " ")])], 1)], 1), _vm.state.isAddEditNoteDialogOpen ? _c('AddEditNoteDialog', {
    attrs: {
      "entity-name": _vm.entityName,
      "entity-id": _vm.entityId,
      "is-edit-mode": _vm.state.isEditMode,
      "note-to-edit": _vm.state.noteToEdit
    },
    on: {
      "reload-notes": function reloadNotes($event) {
        return _vm.$emit('reload-notes');
      },
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditNoteDialog();
      }
    },
    model: {
      value: _vm.state.isAddEditNoteDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isAddEditNoteDialogOpen", $$v);
      },
      expression: "state.isAddEditNoteDialogOpen"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }