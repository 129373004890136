import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'

import {
  NoteInput,
  NoteOutput,
  NoteId,
  UseCreateNote,
  UseGetNotes,
  UseGetNotesBasic,
  UseGetNote,
  UseUpdateNote,
  UseDeleteNote,
} from '@/api/types/note'
import { PromiseType } from '@/utils/types/PromiseType'
import { BasicEntity } from '@/api/types/misc'

const VERSION = 'v1'
const RESOURCE = 'notes'

function useGetNotes(): UseGetNotes {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetNotes['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useGetNotesBasic(): UseGetNotesBasic {
  const axios = useAxios<BasicEntity[]>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/basic` })
  return axios
}

function useGetNote(): UseGetNote {
  const axios = useAxios<PromiseType<ReturnType<UseGetNote['getNote']>>>({ method: 'GET' })

  function getNote(id: NoteId): Promise<NoteOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    getNote,
  }
}

function useCreateNote(): UseCreateNote {
  const axios = useAxios<PromiseType<ReturnType<UseCreateNote['createNote']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createNote(data: NoteInput): Promise<NoteOutput> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createNote,
  }
}

function useUpdateNote(): UseUpdateNote {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateNote['updateNote']>>>({ method: 'PUT' })

  function updateNote(id: NoteId, data: NoteOutput): Promise<NoteOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateNote,
  }
}

function useDeleteNote(): UseDeleteNote {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteNote['deleteNote']>>>({ method: 'DELETE' })

  function deleteNote(id: NoteId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteNote,
  }
}

export { useGetNotes, useGetNote, useGetNotesBasic, useCreateNote, useUpdateNote, useDeleteNote }
