
import { defineComponent, PropType, reactive, ref } from '@vue/composition-api'
import { mdiPencil, mdiDelete } from '@mdi/js'

import { useDeleteNote, useGetNote } from '@/api/note'

import { handleError } from '@/utils/handleError'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { SupplierId } from '@/api/types/supplier'
import { NoteOutput } from '@/api/types/note'
import { ContractId } from '@/api/types/contract'
import { AssignmentId } from '@/api/types/assignment'
import { TaskId } from '@/api/types/task'
import { EquipmentId } from '@/api/types/equipment'
import { Rights } from '@/api/types/right'

export default defineComponent({
  name: 'CommonNotesDialog',
  components: {
    AddEditNoteDialog: () => import('@/views/contractData/components/AddEditNoteDialog.vue'),
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    notes: {
      type: Array as PropType<NoteOutput[]>,
      required: true,
    },
    entityName: {
      type: String,
      required: true,
    },
    entityId: {
      type: Number as PropType<SupplierId | ContractId | AssignmentId | TaskId | EquipmentId>,
      default: null,
    },
  },
  setup: (_, { emit }) => {
    const isAddEditNoteDialogOpen = ref(false)

    function onCloseAddEditNoteDialog(): void {
      isAddEditNoteDialogOpen.value = false

      isEditMode.value = false

      noteToEdit.value = null
    }

    const { deleteNote } = useDeleteNote()

    async function onClickDelete(note: NoteOutput): Promise<void> {
      try {
        await deleteNote(note.id)

        emit('reload-notes')
      } catch (error: unknown) {
        handleError(error)
      }
    }

    const isEditMode = ref(false)

    const noteToEdit = ref<NoteOutput | null>(null)

    const { getNote } = useGetNote()

    async function onClickEdit(note: NoteOutput): Promise<void> {
      isEditMode.value = true

      isAddEditNoteDialogOpen.value = true

      noteToEdit.value = await getNote(note.id)
    }

    return reactive({
      icons: {
        mdiPencil,
        mdiDelete,
      },
      constants: {
        Rights,
      },
      state: {
        isAddEditNoteDialogOpen,

        isEditMode,
        noteToEdit,
      },
      listeners: {
        onCloseAddEditNoteDialog,

        onClickDelete,

        onClickEdit,
      },
      functions: {
        hasSufficientRights,
      },
    })
  },
})
